import React from "react";
import "./styles.css";
const logo = require("./bank-illustration-transparent.png");

const Footer = () => {
  const image = require("./footer3.png");
  return (
    <div className="footer">
      <div className="footerImageBase">
        <img className="footerImage" src={image} />
      </div>
      <div className="footerInfo">
        <div className="footerInfoBase">
          <div className="row">
            <img src={logo} className="footerLogo" />
            <div className="footerBaseInner">
              <div className="footerRow">
                <div>
                  <h3 className="contact_text">Contact</h3>
                  <a
                    className="footerCell email_text"
                    href="mailto:info@joinmaha.com"
                  >
                    info@hassala.app
                  </a>
                  <p>The GrEEK Campus, 171 Tahrir Street, Cairo, Egypt 11513</p>
                </div>
                <div className="footerCell">
                  <h5>© {new Date().getFullYear()} Hassala Technology</h5>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="footerRow">
            <div className="footerCellCopy">
              <h5>© {new Date().getFullYear()} Hassala Technology</h5>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
