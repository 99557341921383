import React from "react";
import "./styles.css";
const WhyMaha = ({ whyMahaRef }) => {
  const image = require("./whyMaha.png");
  const icon1 = require("./icon1.png");
  const icon2 = require("./icon2.png");
  const icon3 = require("./icon3.png");
  return (
    <div ref={whyMahaRef} className="whyMaha" id="why-hassala">
      <h1 style={{ fontSize: 36 }}>Why Hassala</h1>
      <div className="whyMahaGrid">
        <div className="whyMahaImageBase">
          <img src={image} className="whyMahaImage" alt="About image" />
        </div>
        <div className="whyMahaIcons">
          <div className="whyMahaIconCard">
            <div className="whyMahaIconTitle">
              <img src={icon1} className="whyMahaIcon" alt="About image" />
              <h4>OUTREACH</h4>
            </div>
            <p>
              Scale up the distribution of your financial education with a
              digital solution that is fun, accessible and easy to use.
            </p>
          </div>

          <div className="whyMahaIconCard">
            <div className="whyMahaIconTitle">
              <img src={icon2} className="whyMahaIcon" alt="About image" />
              <h4>DATA</h4>
            </div>
            <p>
              Access real-time analytics to assess the needs of learners,
              identify knowledge gaps and demonstrate the impact of financial
              eduction on your community.
            </p>
          </div>

          <div className="whyMahaIconCard">
            <div className="whyMahaIconTitle">
              <img src={icon3} className="whyMahaIcon" alt="About image" />
              <h4>MARKETING</h4>
            </div>
            <p>
              Enhance the understanding of your products and services, build
              trust and increase brand awareness with wider audiences.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyMaha;
