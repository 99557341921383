import React from "react";
import "./styles.css";
const About = ({ aboutRef }) => {
  const image = require("./about.png");

  return (
    <div ref={aboutRef} className="about">
      <div className="aboutText">
        <h2>Your Partner in Financial</h2>
        <h2>Education</h2>
        <p>
          Hassala is a web app that will guide your community on their journey
          to financial wellbeing. We deliver curated content in a bite-sized
          format and make the learning experience fun with gamification and
          rewards.
        </p>
      </div>
      <div className="aboutImage">
        <img src={image} className="image" alt="About image" />
      </div>
    </div>
  );
};

export default About;
